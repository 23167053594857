
import { defineComponent, onMounted, ref } from 'vue';

import { ElLoading } from 'element-plus';

import DashboardLayout from '@/layouts/DashboardLayout.vue';

import useNote from '@/uses/useNote';

export default defineComponent({
  name: 'Notes',

  components: {
    DashboardLayout,
  },

  setup() {
    const { notesResponse, fetchNotes } = useNote();

    const columns = ref([{
      prop: 'name',
      label: 'Name',
    }, {
      prop: 'objectType',
      label: 'Object Type',
    }, {
      prop: 'objectId',
      label: 'Object ID',
    }, {
      prop: 'summary',
      label: 'Summary',
    }, {
      prop: 'creator',
      label: 'Creator',
    }]);

    onMounted(async (): Promise<void> => {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      try {
        const notesRequest = {
          organization_id: '1',
          dealer_id: '1',
          note_dimensions: [],
        };
        await fetchNotes(notesRequest);
      } catch (error) {
        console.error(error);
      } finally {
        loadingInstance.close();
      }
    });

    return {
      columns,
      notesResponse,
    };
  },
});

